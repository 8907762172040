import { useLocation } from 'react-router-dom';

import { c } from 'ttag';

import {
    Sidebar as AppSidebar,
    SidebarList,
    SidebarListItem,
    SidebarNav,
    useAuthentication,
} from '@freelancelabs/components';
import {
    BriefCaseOutline,
    BriefCaseSolid,
    ChatBubbleLeftRightOutline,
    ChatBubbleLeftRightSolid,
    HomeOutline,
    HomeSolid,
    InvoiceOutline,
    InvoiceSolid,
    LogoutOutline,
    PieChartOutline,
    PieChartSolid,
} from '@freelancelabs/icons';

import { AppRoutes, DataIntercomTarget } from '../../constants';

const Sidebar = () => {
    const { logout } = useAuthentication();
    const { pathname } = useLocation();
    return (
        <AppSidebar>
            <SidebarNav>
                <SidebarList>
                    <SidebarListItem
                        active={pathname === AppRoutes.HOME}
                        title={c('Sidebar').t`Home`}
                        icon={<HomeOutline />}
                        activeIcon={<HomeSolid />}
                        to={AppRoutes.HOME}
                        tooltipProps={{ 'data-intercom-target': DataIntercomTarget.CTA_HOME }}
                    />
                    <SidebarListItem
                        active={pathname === AppRoutes.REPORTS}
                        title={c('Sidebar').t`Reports`}
                        icon={<PieChartOutline />}
                        activeIcon={<PieChartSolid />}
                        to={AppRoutes.REPORTS}
                        tooltipProps={{ 'data-intercom-target': DataIntercomTarget.CTA_REPORTS }}
                    />
                    <SidebarListItem
                        title={c('Sidebar').t`Deliveries`}
                        icon={<BriefCaseOutline />}
                        activeIcon={<BriefCaseSolid />}
                        to={AppRoutes.DELIVERIES}
                        active={pathname === AppRoutes.DELIVERIES}
                        tooltipProps={{ 'data-intercom-target': DataIntercomTarget.CTA_DELIVERIES }}
                    />
                    <SidebarListItem
                        title={c('Sidebar').t`Solicitations`}
                        icon={<ChatBubbleLeftRightOutline />}
                        activeIcon={<ChatBubbleLeftRightSolid />}
                        to={AppRoutes.TECHNICAL_ASSISTANCE}
                        active={pathname.startsWith(AppRoutes.SOLICITATIONS)}
                        tooltipProps={{ 'data-intercom-target': DataIntercomTarget.CTA_SOLICITATIONS }}
                    />
                    <SidebarListItem
                        title={c('Sidebar').t`Documents`}
                        icon={<InvoiceOutline />}
                        activeIcon={<InvoiceSolid />}
                        to={AppRoutes.INVOICES}
                        active={pathname === AppRoutes.INVOICES}
                        tooltipProps={{ 'data-intercom-target': DataIntercomTarget.CTA_DOCUMENTS }}
                    />
                </SidebarList>
                <SidebarList className="flex-1 pb-6 justify-end">
                    {/*<SidebarListItem title={c('Sidebar').t`Account`} icon="account-circle" to="/" />*/}
                    <SidebarListItem
                        title={c('Sidebar').t`Logout`}
                        icon={<LogoutOutline />}
                        to="#"
                        onClick={() => logout()}
                    />
                </SidebarList>
            </SidebarNav>
        </AppSidebar>
    );
};

export default Sidebar;
