import * as React from 'react';
import { Ref, SVGProps, forwardRef } from 'react';

type IconSize = 6 | 12 | 14 | 16 | 20 | 24 | 48 | 64 | 96 | 126;
interface Props extends SVGProps<SVGSVGElement> {
    /** If specified, renders an inline title element */
    title?: string;
    /** The size of the icon */
    size?: IconSize;
    /** How many degrees the icon should be rotated */
    rotate?: number;
    className?: string;
    alt?: string;
}
const SvgBriefCaseSolid = ({ size = 16, rotate, title, className, ...props }: Props, ref: Ref<SVGSVGElement>) => {
    const titleId = title;
    const style = {
        height: size.toString(10).concat('px'),
        width: size.toString(10).concat('px'),
        ...(rotate && {
            transform: 'rotate('.concat(rotate.toString(10)).concat('deg)'),
        }),
    };
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            focusable="false"
            role="img"
            style={style}
            className={className}
            ref={ref}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fillRule="evenodd"
                d="M7.5 5.25a3 3 0 013-3h3a3 3 0 013 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0112 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 017.5 5.455V5.25zm7.5 0v.09a49.488 49.488 0 00-6 0v-.09a1.5 1.5 0 011.5-1.5h3a1.5 1.5 0 011.5 1.5zm-3 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                clipRule="evenodd"
            />
            <path d="M3 18.4v-2.796a4.3 4.3 0 00.713.31A26.226 26.226 0 0012 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 01-6.477-.427C4.047 21.128 3 19.852 3 18.4z" />
        </svg>
    );
};
const ForwardRef = forwardRef(SvgBriefCaseSolid);
export default ForwardRef;
