import React, { AnimationEvent, MouseEvent, ReactNode } from 'react';

import { clsx } from '@freelancelabs/utils';

import { NotificationType } from './interfaces';

const CLASSES = {
    NOTIFICATION: 'notification',
    NOTIFICATION_IN: 'notification--in',
    NOTIFICATION_OUT: 'notification--out',
};

const ANIMATIONS = {
    NOTIFICATION_IN: 'anime-notification-in',
    NOTIFICATION_OUT: 'anime-notification-out',
};

interface Props {
    children: ReactNode;
    type: NotificationType;
    isClosing: boolean;
    onExit: () => void;
    onClick?: (e: MouseEvent<HTMLDivElement>) => void;
}

const Notification = ({ children, type, isClosing, onClick, onExit }: Props) => {
    const handleAnimationEnd = ({ animationName }: AnimationEvent<HTMLDivElement>) => {
        if (animationName === ANIMATIONS.NOTIFICATION_OUT && isClosing) {
            onExit();
        }
    };

    return (
        <div
            aria-atomic="true"
            role="alert"
            className={clsx(
                'notification',
                `notification-${type}`,
                CLASSES.NOTIFICATION,
                CLASSES.NOTIFICATION_IN,
                isClosing && CLASSES.NOTIFICATION_OUT
            )}
            onClick={onClick}
            onAnimationEnd={handleAnimationEnd}
        >
            {children}
        </div>
    );
};

export default Notification;
