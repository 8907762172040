import React from 'react';
import { Outlet } from 'react-router';

import { DashboardLayout } from '@freelancelabs/components';

import Sidebar from './Sidebar';

const Layout = () => {
    return (
        <DashboardLayout sidebar={<Sidebar />}>
            <Outlet />
        </DashboardLayout>
    );
};

export default Layout;
