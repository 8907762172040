import React from 'react';

import { c } from 'ttag';

import { LogoFreelance } from '@freelancelabs/icons';

const LoaderPage = () => {
    return (
        <div className="loader-page">
            <LogoFreelance size={96} />
            <span className="loader-page_text">{c('Info').t`Loading`}</span>
        </div>
    );
};

export default LoaderPage;
