import { getHostname } from '../helpers/url';

export enum Stage {
    LOCAL = 'local',
    DEVELOP = 'develop',
    STAGING = 'staging',
    PREPROD = 'preprod',
    PROD = 'prod',
}

export const isLocalhost = (): boolean => {
    return !getHostname().includes('.freelance.com');
};

export const getStage = (): Stage => {
    if (!isLocalhost()) {
        const parts = getHostname().split('.');
        if (parts.length <= 3) {
            return Stage.PROD;
        }
        return `${parts[0]}` as Stage;
    }

    return Stage.LOCAL;
};

export const getBaseDomain = (): string => {
    const stage = getStage();
    if (stage === Stage.LOCAL) {
        return getHostname();
    }

    return '.freelance.com';
};

export const getStagePrefix = (): string => {
    const stage = getStage();
    if (stage === Stage.PROD) {
        return '';
    }

    return `${stage}_`;
};
