import React from 'react';

import { LoaderPage, useAuthentication, useI18n, useSupport, useTracking } from '@freelancelabs/components';
import { AppName, redirectToApp } from '@freelancelabs/shared';

import supportEvents from '../events/support';
import trackEvents from '../events/tracking';
import { useMeQuery } from '../graphql/__generated__/types';
import Pages from '../pages';

const Setup = () => {
    const { isInitialized: isInitializedAuth, hasSession } = useAuthentication();
    const { isInitialized: isInitializedI18n } = useI18n();
    const { data: me } = useMeQuery();

    const userPortailCientAppScope = me?.Me?.appScopes?.find(
        (appScope) => appScope?.appName === AppName.PORTAIL_CLIENT
    );
    const userConnectedAppScope = me?.Me?.appScopes?.find((appScope) => appScope?.appName === AppName.CONNECTEED);

    const jobTitle = userConnectedAppScope?.jobTitle;
    const customerPortalRole = userPortailCientAppScope?.roles?.[0]?.name;
    const connectedRole = userConnectedAppScope?.roles?.[0]?.name;

    const userData = { ...me?.Me, jobTitle, customerPortalRole, connectedRole };
    useTracking(trackEvents, userData);
    useSupport(supportEvents, userData);

    if (!isInitializedAuth || !isInitializedI18n) {
        return <LoaderPage />;
    }

    if (isInitializedAuth && isInitializedI18n && !hasSession) {
        redirectToApp(AppName.ACCOUNT, { 'redirect-after-login': window.location.href });
        return <LoaderPage />;
    }

    return <Pages />;
};

export default Setup;
