import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './app/App';

import './index.scss';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <>
        <App />
    </>
);
