import * as React from 'react';
import { Ref, SVGProps, forwardRef } from 'react';

type IconSize = 6 | 12 | 14 | 16 | 20 | 24 | 48 | 64 | 96 | 126;
interface Props extends SVGProps<SVGSVGElement> {
    /** If specified, renders an inline title element */
    title?: string;
    /** The size of the icon */
    size?: IconSize;
    /** How many degrees the icon should be rotated */
    rotate?: number;
    className?: string;
    alt?: string;
}
const SvgLogoFreelance = ({ size = 16, rotate, title, className, ...props }: Props, ref: Ref<SVGSVGElement>) => {
    const titleId = title;
    const style = {
        height: size.toString(10).concat('px'),
        width: size.toString(10).concat('px'),
        ...(rotate && {
            transform: 'rotate('.concat(rotate.toString(10)).concat('deg)'),
        }),
    };
    return (
        <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            focusable="false"
            role="img"
            style={style}
            className={className}
            ref={ref}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M11.1307 20.0998C10.3551 20.0857 9.61689 19.7675 9.07779 19.215C8.53868 18.6625 8.24271 17.9208 8.25475 17.1525C8.2427 16.3841 8.53867 15.6424 9.07777 15.0899C9.61687 14.5374 10.3551 14.2191 11.1307 14.205C11.9063 14.2191 12.6445 14.5373 13.1836 15.0898C13.7228 15.6424 14.0187 16.3841 14.0066 17.1525C14.0186 17.9208 13.7227 18.6625 13.1836 19.215C12.6445 19.7675 11.9062 20.0857 11.1307 20.0998ZM11.1307 15.213C10.6204 15.2223 10.1347 15.4317 9.78002 15.7952C9.42533 16.1588 9.2306 16.6468 9.23852 17.1523C9.2306 17.6578 9.42533 18.1458 9.78002 18.5093C10.1347 18.8728 10.6204 19.0822 11.1307 19.0915C11.6409 19.0822 12.1266 18.8728 12.4813 18.5093C12.836 18.1458 13.0308 17.6578 13.0228 17.1523C13.0307 16.6468 12.8359 16.1588 12.4813 15.7954C12.1266 15.4319 11.6409 15.2225 11.1307 15.2132V15.213Z"
                fill="#003CC2"
            />
            <path
                d="M1.27997 14.1756C1.18399 14.0679 1.11112 13.9419 1.06578 13.8054C1.02043 13.6689 1.00355 13.5247 1.01617 13.3816V7.9519H0V13.3818C0 14.0554 0.185395 14.5741 0.550986 14.9258C0.740431 15.0943 0.961632 15.2242 1.20188 15.3078C1.44213 15.3915 1.6967 15.4273 1.95095 15.4131C2.02508 15.4131 2.10108 15.4106 2.17894 15.4054L2.26774 15.3997V14.3855L2.16534 14.3938C1.76556 14.4265 1.46696 14.3532 1.27997 14.1756Z"
                fill="#1A1A1A"
            />
            <path
                d="M2.65476 5.03167V5.08061H1.89478V6.09244H2.65476V10.1857H3.66993V6.09224H4.9229V5.08061H3.66993V5.03207C3.65732 4.88891 3.6742 4.7447 3.71955 4.6082C3.7649 4.4717 3.83776 4.34575 3.93372 4.23798C4.12112 4.05967 4.41951 3.98715 4.8207 4.02004L4.9231 4.02836V3.01396L4.8347 3.00801C4.13152 2.96066 3.58373 3.12352 3.20514 3.48768C2.83955 3.83935 2.65476 4.35884 2.65476 5.03167Z"
                fill="#1A1A1A"
            />
            <path
                d="M6.82461 5.63852V5.0802H5.80884V10.1853H6.82461V7.46406C6.82461 6.95903 6.95581 6.60518 7.226 6.38189C7.4992 6.15569 7.85105 6.04465 8.20598 6.07262L8.30597 6.07737V4.99263H8.21098C7.94373 4.97627 7.67663 5.02687 7.43433 5.13975C7.19203 5.25264 6.98234 5.42417 6.82461 5.63852Z"
                fill="#1A1A1A"
            />
            <path
                d="M5.16478 15.3996C4.83173 15.4041 4.50158 15.3377 4.19663 15.205C3.89167 15.0723 3.619 14.8763 3.39703 14.6303C2.9152 14.1194 2.65365 13.4424 2.66804 12.7433C2.65493 12.0474 2.91648 11.374 3.39703 10.8663C3.61834 10.6188 3.89065 10.4212 4.1956 10.2868C4.50054 10.1524 4.83104 10.0843 5.16478 10.0872C5.48873 10.0748 5.81113 10.1376 6.10627 10.2704C6.40141 10.4033 6.66114 10.6027 6.86474 10.8526V10.2136H7.88051V15.2732H6.86414V14.6342C6.66057 14.884 6.40094 15.0833 6.10591 15.2162C5.81089 15.3491 5.48863 15.4119 5.16478 15.3996ZM5.26918 11.0993C5.0577 11.0935 4.84746 11.133 4.6528 11.215C4.45813 11.2971 4.28363 11.4198 4.14121 11.5748C3.98957 11.7291 3.87093 11.9121 3.79237 12.1129C3.71381 12.3137 3.67696 12.5281 3.68402 12.7433C3.6781 12.9598 3.71545 13.1753 3.79391 13.3774C3.87237 13.5795 3.9904 13.7642 4.14121 13.921C4.28535 14.0732 4.46037 14.1935 4.65478 14.2739C4.84919 14.3543 5.05858 14.393 5.26918 14.3876C5.48139 14.3936 5.6925 14.3551 5.88863 14.2746C6.08476 14.1941 6.26147 14.0734 6.40715 13.9204C6.55786 13.7637 6.6758 13.579 6.7542 13.377C6.83259 13.175 6.86989 12.9597 6.86394 12.7433C6.87106 12.5284 6.83435 12.3144 6.756 12.1139C6.67765 11.9134 6.55927 11.7306 6.40795 11.5765C6.26399 11.4205 6.08772 11.2971 5.89118 11.2147C5.69465 11.1323 5.48248 11.093 5.26918 11.0993Z"
                fill="#1A1A1A"
            />
            <path
                d="M9.94844 10.7114V10.2135H8.93286V15.2731H9.94844V12.5974C9.94844 11.6248 10.4036 11.0892 11.23 11.0892C11.9178 11.0892 12.3122 11.5077 12.3122 12.2384V15.2731H13.328V12.1886C13.3282 10.9119 12.568 10.0871 11.3916 10.0871C11.1188 10.0739 10.8465 10.1234 10.5962 10.2317C10.3458 10.34 10.1242 10.5042 9.94844 10.7114Z"
                fill="#1A1A1A"
            />
            <path
                d="M17.9063 13.5744C17.7793 13.819 17.5854 14.0233 17.3467 14.1638C17.1081 14.3044 16.8343 14.3755 16.5567 14.3691C16.3462 14.3733 16.1372 14.3339 15.943 14.2536C15.7488 14.1732 15.5736 14.0535 15.4287 13.9023C15.1319 13.5893 14.9711 13.1734 14.9809 12.744C14.9742 12.5299 15.0102 12.3165 15.0868 12.1162C15.1635 11.9159 15.2793 11.7325 15.4277 11.5767C15.5727 11.4252 15.748 11.3053 15.9424 11.2248C16.1368 11.1442 16.346 11.1048 16.5567 11.1089C16.8314 11.1011 17.1024 11.1726 17.3368 11.3148C17.5711 11.4569 17.7587 11.6635 17.8767 11.9093L17.9205 12.0048L18.7804 11.4982L18.7416 11.4166C18.5443 11.011 18.2334 10.6701 17.8461 10.4344C17.4588 10.1988 17.0113 10.0783 16.5569 10.0874C16.211 10.0788 15.8673 10.1428 15.5481 10.2752C15.229 10.4075 14.9417 10.6052 14.7051 10.8553C14.4615 11.1056 14.2706 11.4015 14.1436 11.7257C14.0165 12.0499 13.9559 12.3958 13.9652 12.7434C13.9552 13.0925 14.0156 13.4401 14.1426 13.7659C14.2696 14.0916 14.4609 14.3891 14.7051 14.6409C14.9436 14.888 15.2315 15.083 15.5504 15.2136C15.8693 15.3441 16.2121 15.4073 16.5571 15.3991C17.0132 15.4018 17.4612 15.2805 17.8526 15.0486C18.244 14.8167 18.5637 14.4829 18.777 14.0836L18.8212 14.0006L17.9535 13.4836L17.9063 13.5744Z"
                fill="#1A1A1A"
            />
            <path
                d="M3.90023 20.4805C3.6559 20.2286 3.46463 19.9311 3.33759 19.6052C3.21054 19.2793 3.15025 18.9316 3.16025 18.5824C3.15096 18.2348 3.21161 17.8888 3.33865 17.5646C3.46569 17.2404 3.65659 16.9445 3.90023 16.6941C4.13682 16.444 4.4241 16.2464 4.74323 16.114C5.06236 15.9817 5.40612 15.9177 5.75198 15.9262C6.20617 15.9174 6.65336 16.038 7.04039 16.2736C7.42743 16.5093 7.73804 16.85 7.93532 17.2554L7.97432 17.337L7.11435 17.8436L7.07115 17.7489C6.95322 17.503 6.76565 17.2964 6.53128 17.1543C6.2969 17.0122 6.02586 16.9407 5.75118 16.9485C5.54069 16.9443 5.33164 16.9837 5.13738 17.0641C4.94313 17.1445 4.76794 17.2642 4.62301 17.4155C4.47468 17.5713 4.35886 17.7546 4.28219 17.9548C4.20552 18.1551 4.16951 18.3684 4.17622 18.5824C4.16634 19.0118 4.32716 19.4277 4.62401 19.7407C4.76893 19.8919 4.9441 20.0115 5.13832 20.0918C5.33254 20.1722 5.54155 20.2115 5.75198 20.2073C6.02949 20.2137 6.30323 20.1427 6.5419 20.0023C6.78056 19.8618 6.9745 19.6577 7.10155 19.4132L7.14774 19.3236L8.01552 19.8405L7.97132 19.9236C7.758 20.3229 7.43828 20.6567 7.0469 20.8887C6.65552 21.1206 6.20745 21.2419 5.75138 21.2393C5.40652 21.2473 5.06376 21.184 4.74501 21.0533C4.42627 20.9227 4.13854 20.7276 3.90023 20.4805Z"
                fill="#003CC2"
            />
            <path
                d="M20.5608 21.2387V18.1446C20.5608 17.4622 20.2058 17.0549 19.6115 17.0549C18.9097 17.0549 18.5387 17.5328 18.5387 18.4366V21.2387H17.5229V18.1446C17.5229 17.4622 17.1829 17.0549 16.6115 17.0549C15.9101 17.0549 15.4916 17.5714 15.4916 18.4366V21.2387H14.4756V16.1792H15.4914V16.6363C15.652 16.446 15.8544 16.2945 16.0831 16.1934C16.3117 16.0923 16.5607 16.0442 16.8109 16.0528C17.0901 16.04 17.3675 16.1022 17.614 16.2328C17.8604 16.3634 18.0666 16.5575 18.2109 16.7946C18.3742 16.5557 18.5965 16.3622 18.8566 16.2324C19.1166 16.1025 19.4059 16.0407 19.6968 16.0528C20.8212 16.0528 21.5768 16.8817 21.5768 18.1155V21.2387H20.5608Z"
                fill="#003CC2"
            />
            <path
                d="M11.1316 18.0738C10.889 18.0698 10.6579 17.9706 10.4891 17.7979C10.3204 17.6252 10.2277 17.3932 10.2316 17.1529C10.2296 17.0338 10.2512 16.9155 10.2954 16.8048C10.3395 16.694 10.4053 16.5929 10.4888 16.5074C10.5724 16.4218 10.6722 16.3533 10.7825 16.3059C10.8927 16.2586 11.0114 16.2332 11.1316 16.2312C11.2517 16.2331 11.3704 16.2585 11.4807 16.3059C11.591 16.3533 11.6907 16.4217 11.7743 16.5073C11.8579 16.5929 11.9236 16.694 11.9678 16.8047C12.0119 16.9155 12.0336 17.0338 12.0315 17.1529C12.0354 17.3932 11.9428 17.6253 11.774 17.798C11.6053 17.9707 11.3742 18.0699 11.1316 18.0738Z"
                fill="#1A1A1A"
            />
            <path
                d="M11.1376 10.1398C10.7848 10.1514 10.4334 10.091 10.1052 9.96233C9.77698 9.8337 9.47896 9.63961 9.22963 9.39211C8.74083 8.87663 8.4777 8.19054 8.49765 7.48356C8.47553 6.7818 8.73433 6.09983 9.21763 5.58631C9.45647 5.33691 9.74585 5.14037 10.0668 5.00959C10.3877 4.87881 10.7329 4.81675 11.0798 4.82748C11.4161 4.81869 11.7501 4.88542 12.0567 5.02267C12.3633 5.15992 12.6345 5.36412 12.8501 5.61999C13.3101 6.13622 13.556 6.80569 13.5385 7.49406C13.5384 7.62609 13.5285 7.75794 13.5089 7.88853L13.4975 7.97016H9.55602C9.74062 8.70739 10.3386 9.14763 11.156 9.14763C11.7546 9.14763 12.176 8.92969 12.4421 8.4837L12.4901 8.40445L13.3601 8.90888L13.3049 8.99546C13.0805 9.36128 12.7609 9.66054 12.3796 9.86188C11.9982 10.0632 11.5692 10.1592 11.1376 10.1398ZM11.0808 5.82069C10.7208 5.80574 10.3675 5.92083 10.0868 6.14456C9.80601 6.36829 9.61683 6.68543 9.55442 7.03699H12.4943C12.3349 6.2738 11.8064 5.8199 11.0798 5.8199L11.0808 5.82069Z"
                fill="#1A1A1A"
            />
            <path
                d="M16.6266 10.1398C16.2738 10.1514 15.9224 10.0909 15.5941 9.9623C15.2659 9.83367 14.9678 9.6396 14.7184 9.39211C14.2298 8.87656 13.9667 8.1905 13.9867 7.48356C13.9645 6.7818 14.2233 6.09983 14.7066 5.58631C14.9455 5.33691 15.2349 5.14037 15.5558 5.00959C15.8767 4.87881 16.2219 4.81675 16.5688 4.82748C16.9051 4.81872 17.2391 4.88546 17.5458 5.02271C17.8524 5.15995 18.1237 5.36414 18.3394 5.61999C18.7988 6.13615 19.0443 6.80531 19.0265 7.49327C19.0265 7.62531 19.0166 7.75716 18.9969 7.88774L18.9865 7.97016H15.0448C15.2296 8.70739 15.8276 9.14763 16.6448 9.14763C17.2434 9.14763 17.6648 8.92969 17.9308 8.48371L17.9788 8.40445L18.8491 8.90888L18.7937 8.99547C18.5694 9.36129 18.2498 9.66057 17.8685 9.86192C17.4872 10.0633 17.0582 10.1593 16.6266 10.1398ZM16.5696 5.82069C16.2096 5.80574 15.8564 5.92083 15.5756 6.14456C15.2948 6.36829 15.1056 6.68543 15.0432 7.03699H17.9832C17.8238 6.2738 17.2952 5.8199 16.5688 5.8199L16.5696 5.82069Z"
                fill="#1A1A1A"
            />
            <path
                d="M21.5983 15.3996C21.2455 15.4111 20.8941 15.3507 20.5659 15.2221C20.2377 15.0935 19.9397 14.8994 19.6903 14.6519C19.2015 14.1364 18.9384 13.4503 18.9583 12.7433C18.9362 12.0416 19.195 11.3596 19.6783 10.8461C19.9172 10.5967 20.2065 10.4001 20.5275 10.2694C20.8484 10.1386 21.1936 10.0765 21.5405 10.0872C21.8768 10.0785 22.2108 10.1452 22.5174 10.2825C22.8241 10.4197 23.0954 10.6239 23.311 10.8798C23.7709 11.3962 24.0167 12.0656 23.9992 12.754C23.9991 12.886 23.9892 13.0178 23.9696 13.1483L23.9582 13.2299H20.0167C20.2013 13.9672 20.7993 14.4074 21.6167 14.4074C22.2153 14.4074 22.6366 14.1895 22.9028 13.7435L22.9508 13.6642L23.8208 14.1687L23.7656 14.2552C23.5412 14.621 23.2216 14.9203 22.8402 15.1217C22.4589 15.323 22.0299 15.419 21.5983 15.3996ZM21.5415 11.0805C21.1815 11.0655 20.8282 11.1806 20.5475 11.4043C20.2667 11.6281 20.0775 11.9452 20.0151 12.2968H22.955C22.7956 11.5336 22.2669 11.0797 21.5405 11.0797L21.5415 11.0805Z"
                fill="#1A1A1A"
            />
        </svg>
    );
};
const ForwardRef = forwardRef(SvgLogoFreelance);
export default ForwardRef;
