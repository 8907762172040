import { frLocale } from './dateFnLocales';

/**
 * The locales are exported as mutable exports:
 * 1) To avoid using a react context for components deep in the tree
 * 2) It's more similar to how ttag works
 */
export let dateLocale = frLocale;
export let localeCode = 'en-US';
export let languageCode = 'en';

export const setLocales = ({
    localeCode: newLocaleCode = localeCode,
    languageCode: newLanguageCode = languageCode,
}) => {
    localeCode = newLocaleCode;
    languageCode = newLanguageCode;
};

export * from './helper';
export * from './locales';
export * from './loadLocale';
