import { addLocale as ttagAddLocale, useLocale as ttagUseLocale } from 'ttag';

import { DEFAULT_LOCALE } from '../constants';
import { TtagLocaleMap } from '../interfaces/Locale';
import { getLanguageCode } from './helper';
import { setLocales } from './index';

export const loadLocale = async (localeCode: string, locales: TtagLocaleMap) => {
    const languageCode = getLanguageCode(localeCode);
    if (localeCode !== DEFAULT_LOCALE) {
        const getLocaleData = locales[localeCode];
        if (!getLocaleData) {
            throw new Error('No locale data for requested localeCode');
        }
        const data = await getLocaleData();
        ttagAddLocale(localeCode, data);
    }
    ttagUseLocale(localeCode);

    setLocales({
        localeCode,
        languageCode,
    });

    document.documentElement.lang = languageCode;
};
