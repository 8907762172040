export enum AppRoutes {
    HOME = '/',
    REPORTS = '/reports',
    DELIVERIES = '/deliveries',
    INVOICES = '/documents/invoices',
    SOLICITATIONS = '/solicitations',
    TECHNICAL_ASSISTANCE = '/solicitations/technical-assistance',
    FLAT_RATE = '/solicitations/flat-rate',
}

export enum AppHash {
    SINGLE_EXPORT = '#single-export',
    RECURRING_EXPORT = '#recurring-export',
}

export enum EventName {
    HOME_PAGE_VIEWED = 'Home Page Viewed',
    REPORTS_PAGE_VIEWED = 'Reports Page Viewed',
    DELIVERIES_PAGE_VIEWED = 'Deliveries Page Viewed',
    INVOICES_PAGE_VIEWED = 'Invoices Page Viewed',
    TECHNICAL_ASSISTANCE_PAGE_VIEWED = 'Technical Assistance Page Viewed',
    FLAT_RATE_PAGE_VIEWED = 'Flat Rate Page Viewed',
    DELIVERIES_RECURRING_EXPORT_MODAL_VIEWED = 'Deliveries Recurring Export Modal Viewed',
    DELIVERIES_RECURRING_EXPORT_ACTIVATED = 'Deliveries Recuring Export Activated',
    FILTER_CHANGED = 'Filter Changed',
    SORTING_CHANGED = 'Sorting Changed',
    EXPORT_CTA_CLICKED = 'Export CTA Clicked',
    EXPORT_REQUESTED = 'Export Requested',
    DISPLAY_COLUMNS_CTA_CLICKED = 'Display Columns CTA Clicked',
    DISPLAYED_COLUMNS_CHANGED = 'Displayed Columns Changed',
    CHART_TITLE_TOOLTIP_HOVER_VIEWED = 'Chart Title Tooltip Hover Viewed',
}

export const SUPPORT_EVENT_PREFIX = 'PC - ';

export enum DataIntercomTarget {
    CTA_HOME = 'CTA home',
    CTA_REPORTS = 'CTA reports',
    CTA_DELIVERIES = 'CTA deliveries',
    CTA_DOCUMENTS = 'CTA documents',
    CTA_SOLICITATIONS = 'CTA solicitations',
    CTA_EXPORT = 'CTA export',
    CTA_SINGLE_EXPORT = 'CTA single export',
    CTA_CONFIGURE_RECURRING_EXPORT = 'CTA configure recurring export',
    CTA_REGISTER_RECURRING_EXPORT = 'CTA register recurring export',
    FIRST_DELIVERY = 'First delivery',
    DATE_FILTER = 'Date filter',
    STATUS_FILTER = 'Status filter',
    TYPE_FILTER = 'Type filter',
    DEPARTMENT_FILTER = 'Department filter',
    OPERATIONAL_FILTER = 'Operational filter',
    COLUMN_DISPLAYER = 'Column displayer',
    COLUMN_DISPLAYER_PANEL = 'Column displayer panel',
    DELIVERY_MODAL = 'Delivery modal',
    CTA_CLOSE_DELIVERY_MODAL = 'CTA close delivery modal',
    DELIVERIES_SINGLE_EXPORT_MODAL = 'Deliveries single export modal',
    CTA_CLOSE_DELIVERIES_SINGLE_EXPORT_MODAL = 'CTA close deliveries single export modal',
}
