import { useContext } from 'react';

import { I18nStore } from '../containers/i18n';
import I18nContext from '../containers/i18n/context';

const useI18n = () => {
    return useContext(I18nContext) as I18nStore;
};

export default useI18n;
