import React, { ReactNode, createContext, useEffect } from 'react';

import { ChevronDoubleLeft, ChevronDoubleRight, LogoFreelance } from '@freelancelabs/icons';
import { clsx } from '@freelancelabs/utils';

import { useLocalState } from '../../../hooks';
import { Button } from '../../index';

interface Props {
    children?: ReactNode;
}
export const SidebarContext = createContext({
    isShrink: false,
});

const Sidebar = ({ children }: Props) => {
    const [isShrink, setIsShrink] = useLocalState(false, 'ui-sidebar-shrink');
    useEffect(() => {
        const htmlComputedStyle = getComputedStyle(document.documentElement);
        const rootStyle = document.documentElement.style;
        const setSidebarTransition = () => {
            rootStyle.setProperty(
                '--sidebar-shrink-transition',
                htmlComputedStyle.getPropertyValue('--sidebar-shrink-transition-default')
            );
        };
        if (isShrink) {
            rootStyle.setProperty('--sidebar-size', htmlComputedStyle.getPropertyValue('--sidebar-size-shrink'));
            setSidebarTransition();
            return;
        }
        rootStyle.setProperty('--sidebar-size', htmlComputedStyle.getPropertyValue('--sidebar-size-default'));
        setSidebarTransition();
    }, [isShrink]);
    return (
        <SidebarContext.Provider value={{ isShrink }}>
            <div className="sidebar-container">
                <div className="sidebar-expand">
                    <Button onClick={() => setIsShrink(!isShrink)} category="weak" size="small" icon={true}>
                        {isShrink ? <ChevronDoubleRight /> : <ChevronDoubleLeft />}
                    </Button>
                </div>
                <div className="sidebar">
                    <div className={clsx('sidebar-logo', isShrink ? 'shrink' : '')}>
                        <LogoFreelance size={48} />
                    </div>
                    <div className="sidebar-content">{children}</div>
                </div>
            </div>
        </SidebarContext.Provider>
    );
};

export default Sidebar;
