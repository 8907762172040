import React, {
    ComponentProps,
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useRef,
    useState,
} from 'react';

import { Modal } from '../../components/molecules/modal';

type Props = Omit<ComponentProps<typeof Modal>, 'open' | 'onClose'>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const defaultFunction = (isOpen: boolean, p?: Props): void => {};

const defaultValue = {
    modalRef: {
        current: defaultFunction,
    },
};

const ModalContext = createContext(defaultValue);

function ModalWithContext() {
    const [open, setOpen] = useState(false);
    const [props, setProps] = useState<Props | undefined>();
    const { modalRef } = useContext(ModalContext);

    modalRef.current = (isOpen, props) => {
        setProps(props);
        setOpen(isOpen);
    };

    return (
        // @ts-ignore
        <Modal
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            {...props}
        />
    );
}

export function ModalProvider({ children }: PropsWithChildren) {
    const modalRef = useRef(defaultFunction);
    return (
        <ModalContext.Provider value={{ modalRef }}>
            {children}
            <ModalWithContext />
        </ModalContext.Provider>
    );
}

export function useModal() {
    const { modalRef } = useContext(ModalContext);
    return {
        close: useCallback(() => {
            return modalRef.current(false);
        }, [modalRef]),
        open: useCallback(
            (p: Props) => {
                return modalRef.current(true, p);
            },
            [modalRef]
        ),
    };
}
