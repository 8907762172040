import { getHostname } from '../helpers/url';
import { getStage } from '../stage';

export enum AppName {
    CONNECTEED = 'CONNECTEED',
    PORTAIL_CLIENT = 'PORTAIL_CLIENT',
    ACCOUNT = 'ACCOUNT',
}

type App = {
    [name in AppName]: {
        subdomain: string;
        local: {
            port: number;
        };
    };
};
// @TODO mv to constants ?
const APPS: App = {
    ACCOUNT: {
        subdomain: 'account',
        local: {
            port: 5100,
        },
    },
    PORTAIL_CLIENT: {
        subdomain: 'client',
        local: {
            port: 5101,
        },
    },
    CONNECTEED: {
        subdomain: 'connecteed',
        local: {
            port: 5102,
        },
    },
};

export const redirectToApp = (name: AppName, searchParams?: Record<string, string>) => {
    let url;
    const stage = getStage();

    if (stage === 'prod') {
        url = `https://${APPS[name].subdomain}.freelance.com`;
    } else if (stage === 'local') {
        // local can be localhost:port or IP:port
        url = `http://${getHostname()}:${APPS[name].local.port}`;
    } else {
        // stage can be develop.subdomain.freelance.com or commitNumber.subdomain.freelance.com
        url = `https://${stage}.${APPS[name].subdomain}.freelance.com`;
    }

    if (searchParams) {
        url = `${url}?${new URLSearchParams(searchParams).toString()}`;
    }
    window.location.replace(new URL(url).toString());
};
