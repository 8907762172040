import { useContext } from 'react';

import { AuthenticationStore } from '../containers/authentication';
import AuthenticationContext from '../containers/authentication/authenticationContext';

const useAuthentication = () => {
    return useContext(AuthenticationContext) as AuthenticationStore;
};

export default useAuthentication;
