import * as React from 'react';
import { PolymorphicPropsWithRef } from 'react-polymorphic-types';
import { Link } from 'react-router-dom';

import { clsx } from '@freelancelabs/utils';

import { CircleLoader } from '../loader';

export type ButtonSize = 'small' | 'medium' | 'large';

export type ButtonCategory = 'default' | 'weak' | 'ghost' | 'link' | 'destructive';

export interface ButtonLikeOwnProps {
    /**
     * Controls the signal of the button.
     */
    category?: ButtonCategory;
    /**
     * Whether the button should render a loader.
     * Button is disabled when this prop is true.
     */
    loading?: boolean;
    /**
     * Controls how large the button should be.
     */
    size?: ButtonSize;
    /**
     * Puts the button in a disabled state.
     */
    disabled?: boolean;
    /**
     * If true, the button will take up the full width of its container.
     */
    fullWidth?: boolean;
    /**
     * If true, display as pill.
     */
    pill?: boolean;
    /**
     * If true, display as icon.
     */
    icon?: boolean;
    /**
     * If true, this button is part of a button group.
     */
    group?: boolean;
    /**
     * For a selected item inside a group.
     */
    selected?: boolean;
    /**
     * If defined render a React Link
     */
    to?: string;
    target?: HTMLAnchorElement['target'];
}

export type ButtonProps<E extends React.ElementType> = PolymorphicPropsWithRef<ButtonLikeOwnProps, E>;

const defaultElement = 'button';

const ButtonBase = <E extends React.ElementType = typeof defaultElement>(
    {
        loading = false,
        disabled = false,
        className,
        tabIndex,
        children,
        category = 'default',
        size = 'medium',
        fullWidth,
        pill,
        icon,
        group,
        to,
        target,
        selected = false,
        as,
        ...restProps
    }: ButtonProps<E>,
    ref: typeof restProps.ref
) => {
    const isDisabled = loading || disabled;
    const isUnderlineShape = category === 'link';

    const buttonClassName = clsx(
        isUnderlineShape ? 'button-underline' : 'button',
        !isUnderlineShape && icon && 'button-for-icon',
        `button-${size}`,
        loading && 'is-loading',
        fullWidth && 'button-block',
        group && selected && 'is-selected',
        `button-${category}`,
        restProps.as !== 'button' ? 'inline-block text-center' : '',
        className
    );
    const Element: React.ElementType = as || defaultElement;

    const roleProps = restProps.onClick && !restProps.type ? { role: 'button' } : undefined;

    return (
        <>
            {to ? (
                <Link to={to} ref={ref} target={target} className={buttonClassName} {...roleProps} {...restProps}>
                    <span className="button-container">{children}</span>
                </Link>
            ) : (
                <Element
                    ref={ref}
                    className={buttonClassName}
                    disabled={isDisabled}
                    tabIndex={isDisabled ? -1 : tabIndex}
                    aria-busy={loading}
                    {...roleProps}
                    {...restProps}
                >
                    <span className="button-container">{children}</span>
                    {loading && (
                        <span className="button-loader-container">
                            <CircleLoader />
                        </span>
                    )}
                </Element>
            )}
        </>
    );
};

const Button: <E extends React.ElementType = typeof defaultElement>(
    props: ButtonProps<E>
) => React.ReactElement | null = React.forwardRef(ButtonBase);

export default Button;
