import React, { HTMLAttributes, ReactNode } from 'react';

import { clsx } from '@freelancelabs/utils';

interface Props extends HTMLAttributes<HTMLUListElement> {
    listClassName?: string;
    className?: string;
    children?: ReactNode;
}

const SidebarList = ({ className, listClassName = 'sidebar-list', children, ...rest }: Props) => {
    return (
        <ul className={clsx('list-none', listClassName, className)} {...rest}>
            {children}
        </ul>
    );
};

export default SidebarList;
