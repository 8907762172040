import { useContext, useEffect, useRef } from 'react';

import mixpanel from 'mixpanel-browser';

import { ConsentContext, ConsentStore } from '../containers/consent';

const MIXPANEL_TOKEN = import.meta.env.PUBLIC_MIXPANEL_TOKEN;

interface TrackEvents {
    track: (arg0: { eventName: string; data: object }) => void;
}

export interface User {
    id?: string;
    email?: string;
    firstName?: string | null;
    lastName?: string | null;
    connectedRole?: string | null;
    customerPortalRole?: string | null;
}

const useTracking = (trackEvents: (arg0: TrackEvents) => void, user?: User) => {
    const { mixpanelEnabled } = useContext(ConsentContext) as ConsentStore;
    const eventsInQueue = useRef<{ eventName: string; data?: object }[]>([]);
    const setUserProfile = () => {
        if (user) {
            const { id, email, firstName, lastName, connectedRole, customerPortalRole } = user;
            mixpanel.identify(id);
            mixpanel.people.set({
                $email: email,
                $first_name: firstName,
                $last_name: lastName,
                'Connecteed Role': connectedRole,
                'Customer Portal Role': customerPortalRole,
            });
        }
    };
    useEffect(() => {
        if (!MIXPANEL_TOKEN) {
            return;
        }
        mixpanel.init(MIXPANEL_TOKEN, {
            opt_out_tracking_by_default: !mixpanelEnabled,
        });
        if (mixpanelEnabled) {
            setUserProfile();
        }
        trackEvents({
            track: ({ eventName, data }) => {
                if (mixpanel.has_opted_out_tracking()) {
                    eventsInQueue.current = [...eventsInQueue.current, { eventName, data }];
                } else {
                    mixpanel.track(eventName, data);
                }
            },
        });
    }, []);
    useEffect(() => {
        if (!MIXPANEL_TOKEN) {
            return;
        }
        if (mixpanelEnabled && user) {
            if (mixpanel.has_opted_out_tracking()) {
                mixpanel.opt_in_tracking();
            }
            setUserProfile();
            if (eventsInQueue.current[0]) {
                eventsInQueue.current.forEach(({ eventName, data }) => mixpanel.track(eventName, data));
                eventsInQueue.current = [];
            }
        }
        if (!mixpanelEnabled && mixpanel.has_opted_in_tracking()) {
            mixpanel.opt_out_tracking();
        }
    }, [mixpanelEnabled, user]);
};

export default useTracking;
