import * as React from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { c } from 'ttag';

import { ArrowLeft, Close } from '@freelancelabs/icons';

import { Button, Tooltip } from '../../index';

interface Props {
    open: boolean;
    onClose: () => void;
    title?: string;
    children: React.ReactNode;
    onBack?: () => void;
    size?: number;
    panelProps?: object;
    closeButtonProps?: object;
}

const Modal = ({ open, onClose, onBack, title, children, size, panelProps, closeButtonProps }: Props) => {
    const style = { ...(size && { maxInlineSize: `${size}px` }) };
    return (
        <Transition show={open} as={React.Fragment}>
            <Dialog open={open} onClose={() => onClose()} className="modal">
                <Transition.Child
                    as={React.Fragment}
                    enter="modal_backdrop-enter"
                    enterFrom="modal_backdrop-enterFrom"
                    enterTo="modal_backdrop-enterTo"
                    leave="modal_backdrop-leave"
                    leaveFrom="modal_backdrop-leaveFrom"
                    leaveTo="modal_backdrop-leaveTo"
                >
                    <div className="modal_backdrop" aria-hidden="true" />
                </Transition.Child>
                <Transition.Child
                    as={React.Fragment}
                    enter="modal_panel-enter"
                    enterFrom="modal_panel-enterFrom"
                    enterTo="modal_panel-enterTo"
                    leave="modal_panel-leave"
                    leaveFrom="modal_panel-leaveFrom"
                    leaveTo="modal_panel-leaveTo"
                >
                    <Dialog.Panel className="modal_panel-container" as="dialog" style={style} {...panelProps}>
                        <main className="modal_panel">
                            <header>
                                <Dialog.Title>
                                    {onBack && (
                                        <Button onClick={onBack} size="small" category="ghost" icon>
                                            <ArrowLeft />
                                        </Button>
                                    )}
                                    {title}
                                </Dialog.Title>
                                <Tooltip tabIndex={0} title={c('Modal Tooltip').t`Close`}>
                                    <Button
                                        onClick={() => onClose()}
                                        icon
                                        size="small"
                                        category="ghost"
                                        {...closeButtonProps}
                                    >
                                        <Close />
                                    </Button>
                                </Tooltip>
                            </header>
                            {children}
                        </main>
                    </Dialog.Panel>
                </Transition.Child>
            </Dialog>
        </Transition>
    );
};

export default Modal;
