import * as React from 'react';

import { clsx } from '@freelancelabs/utils';

import { Tooltip } from '../tooltip';

// @Later handle resize, ie textIsTooLong is not reactive
interface Props extends React.HTMLProps<HTMLSpanElement> {
    text: string;
    /**
     * number of characters you want to keep at the end: 6 will give “blablabla…la.png’”
     */
    charsToDisplayEnd?: number;
    className?: string;
    /**
     * When text is too long, title can be used to show the full text.
     */
    displayTitle?: Boolean;
    /**
     * When text is too long, tooltip can be used to show the full text.
     */
    displayTooltip?: Boolean;
}

const Ellipsis = ({
    text,
    className = '',
    displayTitle = false,
    displayTooltip = false,
    charsToDisplayEnd = 0,
    ...rest
}: Props) => {
    const [start, end] = React.useMemo(() => {
        return [text.slice(0, -charsToDisplayEnd), text.slice(-charsToDisplayEnd)];
    }, [text]);

    const ref = React.useRef<HTMLSpanElement>(null);
    const [tooltipTitle, setTooltipTitle] = React.useState<string | null>(null);
    const [titleText, setTitleText] = React.useState<string | null>(null);
    React.useEffect(() => {
        if (!ref.current) {
            return;
        }
        const textIsTooLong = ref.current.offsetWidth < ref.current.scrollWidth;
        if (textIsTooLong) {
            if (displayTitle) {
                setTitleText(text);
            }
            if (displayTooltip) {
                setTooltipTitle(text);
            }
        } else {
            setTooltipTitle(null);
            setTitleText(null);
        }
    });

    const noMiddleEllipsis = (
        <span ref={ref} className="ellipsis-start" aria-hidden="true">
            {text}
        </span>
    );

    const middleEllipsis = (
        <>
            {start && (
                <span ref={ref} className="ellipsis-start" aria-hidden="true">
                    {start}
                </span>
            )}
            <span className="ellipsis-end" aria-hidden="true">
                {end}
            </span>
        </>
    );

    return (
        <Tooltip title={tooltipTitle} originalPlacement="top">
            <span aria-label={text} title={titleText || undefined} className={clsx([className, 'ellipsis'])} {...rest}>
                {charsToDisplayEnd > 0 ? middleEllipsis : noMiddleEllipsis}
            </span>
        </Tooltip>
    );
};

export default Ellipsis;
