import React, { LiHTMLAttributes, ReactNode, useContext } from 'react';
import { Link } from 'react-router-dom';

import { clsx } from '@freelancelabs/utils';

import { Ellipsis, SidebarContext, Tooltip } from '../../../index';

interface Props extends LiHTMLAttributes<HTMLLIElement> {
    title: string;
    to: string;
    active?: boolean;
    icon: ReactNode;
    activeIcon?: ReactNode;
    className?: string;
    tooltipProps?: object;
}

const SidebarListItem = ({
    title,
    icon,
    activeIcon,
    to,
    active = false,
    className = '',
    children,
    tooltipProps,
    ...rest
}: Props) => {
    const { isShrink } = useContext(SidebarContext);
    return (
        <li className={clsx('sidebar-list-item', isShrink && 'shrink', active && 'active')} {...rest}>
            <Tooltip title={title} originalPlacement="right" type="contrasted" {...tooltipProps} disabled={!isShrink}>
                <Link className={clsx('sidebar-list-item__link', className)} to={to}>
                    {active && activeIcon ? activeIcon : icon}
                    {!isShrink && (
                        <Ellipsis text={title} displayTitle={true}>
                            {title}
                        </Ellipsis>
                    )}
                </Link>
            </Tooltip>
        </li>
    );
};

export default SidebarListItem;
