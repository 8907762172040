import React, { useRef, useState } from 'react';

import { ConsentStatus, DidomiSDK, IDidomiObject } from '@didomi/react';

import Context from './context';

interface Props {
    children: JSX.Element | JSX.Element[];
}

const ConsentProvider = ({ children }: Props) => {
    const didomi = useRef<IDidomiObject>();
    const [mixpanelEnabled, setMixpanelEnabled] = useState<ConsentStatus>();
    const DIDOMI_API_KEY = import.meta.env.PUBLIC_DIDOMI_API_KEY;
    if (!DIDOMI_API_KEY) {
        return <>{children}</>;
    }
    const setMixpanel = () => setMixpanelEnabled(didomi?.current?.getUserConsentStatusForVendor('c:mixpanel'));
    const openConsent = () => didomi?.current?.preferences?.show();
    return (
        <>
            <DidomiSDK
                apiKey={DIDOMI_API_KEY}
                onReady={(didomiObject: IDidomiObject) => {
                    didomi.current = didomiObject;
                    setMixpanel();
                }}
                onConsentChanged={setMixpanel}
            />
            <Context.Provider value={{ mixpanelEnabled, openConsent }}>{children}</Context.Provider>
        </>
    );
};

export default ConsentProvider;
