import { getStage } from '../stage';
import { Stage } from '../stage';

export * from './user';
export * from './passwordLess';
export const getInsideApiUrl = (path: string): string => {
    const scheme = 'https://';
    const baseUrl = 'api.inside.freelance.com/';
    const stage = getStage();

    if (stage === Stage.PROD) {
        return `${scheme}${baseUrl}${path}`;
    }

    // Assume we use develop api in local
    if (Stage.LOCAL === stage) {
        return `${scheme}${Stage.DEVELOP}.${baseUrl}${path}`;
    }

    return `${scheme}${stage}.${baseUrl}${path}`;
};
