import * as React from 'react';
import { Ref, SVGProps, forwardRef } from 'react';

type IconSize = 6 | 12 | 14 | 16 | 20 | 24 | 48 | 64 | 96 | 126;
interface Props extends SVGProps<SVGSVGElement> {
    /** If specified, renders an inline title element */
    title?: string;
    /** The size of the icon */
    size?: IconSize;
    /** How many degrees the icon should be rotated */
    rotate?: number;
    className?: string;
    alt?: string;
}
const SvgHomeSolid = ({ size = 16, rotate, title, className, ...props }: Props, ref: Ref<SVGSVGElement>) => {
    const titleId = title;
    const style = {
        height: size.toString(10).concat('px'),
        width: size.toString(10).concat('px'),
        ...(rotate && {
            transform: 'rotate('.concat(rotate.toString(10)).concat('deg)'),
        }),
    };
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            focusable="false"
            role="img"
            style={style}
            className={className}
            ref={ref}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
            <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
        </svg>
    );
};
const ForwardRef = forwardRef(SvgHomeSolid);
export default ForwardRef;
