import { useEffect, useRef } from 'react';

import initialize from './initialize';

const PUBLIC_INTERCOM_APP_ID = import.meta.env.PUBLIC_INTERCOM_APP_ID;

declare global {
    interface Window {
        Intercom: any;
    }
}

interface SupportEvents {
    track: (arg0: { eventName: string; data: object }) => void;
    update: (arg0: object) => void;
}

interface User {
    email?: string;
    id?: string;
    firstName?: string | null;
    lastName?: string | null;
    jobTitle?: string | null;
    connectedRole?: string | null;
    customerPortalRole?: string | null;
}
interface IntercomData {
    api_base: string;
    app_id: string;
    email?: string;
    user_id?: string;
    name?: string | null;
    jobtile?: string | null;
    connecteed_role?: string | null;
    customer_portal_role?: string | null;
    hide_default_launcher?: boolean;
}

interface Config {
    hideDefaultLauncher?: boolean;
}

const useSupport = (supportEvents: (arg0: SupportEvents) => void, user?: User, config?: Config) => {
    const isBooted = useRef(false);
    let intercomData: IntercomData;
    if (user) {
        const { id, email, firstName, lastName, jobTitle, connectedRole, customerPortalRole } = user;
        intercomData = {
            api_base: 'https://api-iam.intercom.io',
            app_id: PUBLIC_INTERCOM_APP_ID,
            email,
            user_id: id,
            name: firstName && lastName && `${firstName} ${lastName}`,
            jobtile: jobTitle,
            connecteed_role: connectedRole,
            customer_portal_role: customerPortalRole,
            hide_default_launcher: config?.hideDefaultLauncher,
        };
    }
    const boot = () => {
        window.Intercom('boot', intercomData);
        isBooted.current = true;
    };
    useEffect(() => {
        if (!PUBLIC_INTERCOM_APP_ID) {
            return;
        }
        initialize(PUBLIC_INTERCOM_APP_ID);
        if (user) {
            boot();
        }
        supportEvents({
            track: ({ eventName, data }) => window.Intercom('trackEvent', eventName, data),
            update: (data) => window.Intercom('update', { ...intercomData, ...data }),
        });
    }, []);

    useEffect(() => {
        if (!PUBLIC_INTERCOM_APP_ID || !user) {
            return;
        }
        if (isBooted.current) {
            window.Intercom('update', intercomData);
        } else {
            boot();
        }
    }, [user]);
};

export default useSupport;
