import * as React from 'react';

import { clsx, isTruthy } from '@freelancelabs/utils';

import { generateUID } from '../../../helpers';
import { useCombinedRefs, useInstance } from '../../../hooks';
import { Popper, PopperPlacement, usePopper, usePopperState } from '../popper';
import { TooltipExclusiveContext } from './TooltipExclusive';
import useTooltipHandlers from './useTooltipHandlers';

export type TooltipType = 'info' | 'error' | 'warning' | 'contrasted';

interface Props extends Omit<React.HTMLProps<HTMLElement>, 'title' | 'children'> {
    children: React.ReactElement;
    title: React.ReactNode;
    originalPlacement?: PopperPlacement;
    type?: TooltipType;
    anchorOffset?: { x: number; y: number };
    isOpen?: boolean;
    relativeReference?: Parameters<typeof usePopper>[0]['relativeReference'];
    openDelay?: number;
    closeDelay?: number;
    longTapDelay?: number;
    updateAnimationFrame?: boolean;
    disabled?: boolean;
}

const getTooltipTypeClass = (type: TooltipType) => {
    if (type === 'error') {
        return 'tooltip-danger';
    }
    if (type === 'warning') {
        return 'tooltip-warning';
    }
    if (type === 'contrasted') {
        return 'tooltip-contrasted';
    }
};

const mergeCallbacks = (a: any, b: any) => {
    return Object.fromEntries(
        Object.entries(a).map(([key, cb]: [string, any]) => {
            const otherCb = b[key];
            return [
                key,
                otherCb
                    ? (...args: any[]) => {
                          cb(...args);
                          otherCb(...args);
                      }
                    : cb,
            ];
        })
    );
};

const TooltipBase = (
    {
        children,
        title,
        originalPlacement = 'top',
        type = 'info',
        anchorOffset,
        isOpen: isExternalOpen,
        relativeReference,
        openDelay,
        disabled = false,
        closeDelay,
        longTapDelay,
        updateAnimationFrame,
        ...rest
    }: Props,
    ref: React.Ref<HTMLElement>
) => {
    const uid = useInstance(() => generateUID('tooltip'));

    const { open, close, isOpen } = usePopperState();
    const combinedIsOpen = isExternalOpen || isOpen;
    const { floating, reference, position, arrow, placement } = usePopper({
        isOpen: combinedIsOpen,
        originalPlacement,
        relativeReference,
    });

    const exclusive = React.useContext(TooltipExclusiveContext) || {};

    const tooltipHandlers = useTooltipHandlers({
        open,
        close,
        isOpen,
        isExternalOpen,
        openDelay,
        closeDelay,
        longTapDelay,
    });

    const child = React.Children.only(children);
    // Types are wrong? Not sure why ref doesn't exist on a ReactElement
    // @ts-ignore
    const mergedRef = useCombinedRefs(child?.ref, reference, ref);

    React.useEffect(() => {
        if (combinedIsOpen) {
            exclusive.add?.(uid);
        } else {
            exclusive.remove?.(uid);
        }
    }, [isOpen, isExternalOpen]);

    if (!title) {
        return React.cloneElement(child, {
            ref: mergedRef,
            ...rest,
        });
    }

    if (!child) {
        return null;
    }

    return (
        <>
            {React.cloneElement(child, {
                ref: mergedRef,
                ...rest,
                ...mergeCallbacks(tooltipHandlers, child.props),
                'aria-describedby': [child.props['aria-describedby'], uid].filter(isTruthy).join(' '),
            })}
            {!disabled && (
                <Popper
                    divRef={floating}
                    id={uid}
                    isOpen={(exclusive.last === uid || !exclusive.last) && !!title && combinedIsOpen}
                    style={{ ...position, ...arrow }}
                    className={clsx('tooltip', `tooltip--${placement}`, getTooltipTypeClass(type))}
                >
                    {title}
                </Popper>
            )}
        </>
    );
};

const Tooltip = React.forwardRef<HTMLElement, Props>(TooltipBase);

export default Tooltip;
