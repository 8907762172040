import * as React from 'react';
import { Ref, SVGProps, forwardRef } from 'react';

type IconSize = 6 | 12 | 14 | 16 | 20 | 24 | 48 | 64 | 96 | 126;
interface Props extends SVGProps<SVGSVGElement> {
    /** If specified, renders an inline title element */
    title?: string;
    /** The size of the icon */
    size?: IconSize;
    /** How many degrees the icon should be rotated */
    rotate?: number;
    className?: string;
    alt?: string;
}
const SvgClose = ({ size = 16, rotate, title, className, ...props }: Props, ref: Ref<SVGSVGElement>) => {
    const titleId = title;
    const style = {
        height: size.toString(10).concat('px'),
        width: size.toString(10).concat('px'),
        ...(rotate && {
            transform: 'rotate('.concat(rotate.toString(10)).concat('deg)'),
        }),
    };
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            focusable="false"
            role="img"
            style={style}
            className={className}
            ref={ref}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
    );
};
const ForwardRef = forwardRef(SvgClose);
export default ForwardRef;
