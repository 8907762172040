import psl from 'psl';

const checkSSR = () => {
    if (typeof window === 'undefined') {
        throw new Error('Not working on SSR');
    }
};

/**
 * Extract host
 */
export const getHost = (url?: string): string => {
    if (!url) {
        checkSSR();
        return window.location.hostname;
    }

    const { host } = new URL(url);
    return host;
};

/**
 * Extract hostname
 */
export const getHostname = (url?: string): string => {
    if (!url) {
        checkSSR();
        return window.location.hostname;
    }

    const { hostname = '' } = new URL(url);
    return hostname;
};

export const getDomainFromHostname = (): string | null => {
    return psl.isValid(window.location.hostname) ? psl.get(window.location.hostname) : window.location.hostname;
};
