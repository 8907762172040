import { LocalesAvailable } from './interfaces/Locale';

export const DEFAULT_TIMEOUT = 30000; // default fetch timeout
export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;
export const MONTH = 4 * WEEK;
export const YEAR = 12 * MONTH;

export const DEFAULT_CURRENCY = 'EUR';

export const DEFAULT_LOCALE = 'en-US';

export const LOCALES: LocalesAvailable = {
    en: 'English',
    fr: 'Français',
    it: 'Italiano',
};

export enum SORT_DIRECTION {
    ASC = 'ASC',
    DSC = 'DSC',
}
