import { LocaleData } from 'ttag';

import { LOCALES } from '../constants';
import { LocalesAvailable, TtagLocaleMap } from '../interfaces/Locale';

export let locales: TtagLocaleMap = {};

export const getLocalesFromRequireContext = (locales: Record<string, () => Promise<LocaleData>>) => {
    return Object.keys(locales).reduce<TtagLocaleMap>((acc, key) => {
        acc[
            key
                .slice(0, key.length - 5)
                .replaceAll('../', '')
                .replace('locales/', '')
        ] = locales[key];
        return acc;
    }, {});
};

const setLocales = (newLocales: TtagLocaleMap) => {
    locales = newLocales;
};

export const getAvailableLocales = (): LocalesAvailable => {
    return Object.keys(locales).reduce<LocalesAvailable>((acc, key) => {
        acc[key] = LOCALES[key];
        return acc;
    }, {});
};

export const initLocales = (locales: Record<string, () => Promise<LocaleData>>): TtagLocaleMap => {
    const newLocales = getLocalesFromRequireContext(locales);
    setLocales(newLocales);
    return newLocales;
};
