import { eventBus } from '@freelancelabs/shared';

import { EventName } from '../constants';

const trackEvents = ({ track }) => {
    eventBus.on(EventName.DASHBOARD_PAGE_VIEWED, () => {
        track({ eventName: EventName.DASHBOARD_PAGE_VIEWED });
    });

    eventBus.on(EventName.DELIVERIES_PAGE_VIEWED, () => {
        track({ eventName: EventName.DELIVERIES_PAGE_VIEWED });
    });

    eventBus.on(EventName.INVOICES_PAGE_VIEWED, () => {
        track({ eventName: EventName.INVOICES_PAGE_VIEWED });
    });

    eventBus.on(EventName.TECHNICAL_ASSISTANCE_PAGE_VIEWED, () => {
        track({ eventName: EventName.TECHNICAL_ASSISTANCE_PAGE_VIEWED });
    });

    eventBus.on(EventName.FLAT_RATE_PAGE_VIEWED, () => {
        track({ eventName: EventName.FLAT_RATE_PAGE_VIEWED });
    });

    eventBus.on(EventName.DISPLAY_COLUMNS_CTA_CLICKED, () => {
        track({ eventName: EventName.DISPLAY_COLUMNS_CTA_CLICKED });
    });

    eventBus.on(EventName.DISPLAYED_COLUMNS_CHANGED, ({ id, isAdded }) => {
        track({
            eventName: EventName.DISPLAYED_COLUMNS_CHANGED,
            data: { Column: id, Action: isAdded ? 'Column added' : 'Column hidden' },
        });
    });

    eventBus.on(EventName.FILTER_CHANGED, ({ id, input }) => {
        track({ eventName: EventName.FILTER_CHANGED, data: { 'Filter Type': id, Input: input } });
    });

    eventBus.on(EventName.SORTING_CHANGED, ({ id, direction }) => {
        track({ eventName: EventName.SORTING_CHANGED, data: { 'Column Sorted': id, Direction: direction } });
    });

    eventBus.on(EventName.EXPORT_CTA_CLICKED, () => {
        track({ eventName: EventName.EXPORT_CTA_CLICKED });
    });

    eventBus.on(EventName.EXPORT_REQUESTED, ({ allColumns, format }) => {
        track({
            eventName: EventName.EXPORT_REQUESTED,
            data: { 'All Columns': allColumns ? 'Yes' : 'No', Format: format },
        });
    });

    eventBus.on(EventName.DELIVERIES_RECURRING_EXPORT_MODAL_VIEWED, () => {
        track({ eventName: EventName.DELIVERIES_RECURRING_EXPORT_MODAL_VIEWED });
    });

    eventBus.on(EventName.DELIVERIES_RECURRING_EXPORT_ACTIVATED, ({ format, recurrence }) => {
        track({
            eventName: EventName.DELIVERIES_RECURRING_EXPORT_ACTIVATED,
            data: { Format: format, Recurrence: recurrence },
        });
    });

    eventBus.on(EventName.CHART_TITLE_TOOLTIP_HOVER_VIEWED, ({ id }) => {
        track({ eventName: EventName.CHART_TITLE_TOOLTIP_HOVER_VIEWED, data: { Tooltip: id } });
    });
};

export default trackEvents;
