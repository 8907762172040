import React, { ReactNode } from 'react';

interface Props {
    children: ReactNode;
    sidebar: ReactNode;
}
const DashboardLayout = ({ children, sidebar }: Props) => {
    return (
        <div className="dashboard-layout">
            {sidebar}
            <div className="dashboard-layout__main">{children}</div>
        </div>
    );
};

export default DashboardLayout;
