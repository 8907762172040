import React, { ReactNode, useEffect, useState } from 'react';

import { addDays } from 'date-fns';

import {
    getAvailableLocales,
    getBrowserLocale,
    getClosestLocaleCode,
    getClosestLocaleMatch,
    getCookie,
    loadLocale,
    locales,
    setCookie,
} from '@freelancelabs/shared';
import { getDomainFromHostname } from '@freelancelabs/shared/lib/helpers/url';

import useInstance from '../../hooks/useInstance';
import Context from './context';

interface Props {
    children: ReactNode;
}

const I18nProvider = ({ children }: Props) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [language, setLanguage] = useState('');

    useEffect(() => {
        const run = async () => {
            try {
                await loadLocale(language, locales);
            } catch (e) {
                throw new Error(`Unable to load locale : ${language}`);
            } finally {
                setIsInitialized(true);
            }
        };

        void run();
    }, []);

    const createI18n = () => {
        const cookieDomain = getDomainFromHostname();
        const searchParams = new URLSearchParams(location.search);
        const languageParams = searchParams.get('language');
        const languageCookie = getCookie('locale');
        const browserLocale = getBrowserLocale();
        const localeCode =
            getClosestLocaleMatch(languageParams || languageCookie || '', locales) ||
            getClosestLocaleCode(browserLocale, locales);

        setLanguage(localeCode);

        const changeLocale = async (newLocale: string) => {
            const localeCode = getClosestLocaleCode(newLocale, locales);
            await loadLocale(localeCode, locales); // @TODO update date local
            if (cookieDomain) {
                setCookie({
                    cookieName: 'locale',
                    cookieValue: localeCode,
                    expirationDate: addDays(new Date(), 30).toUTCString(),
                    cookieDomain,
                    path: '/',
                });
            }
            setLanguage(localeCode);
        };

        const availableLocales = getAvailableLocales();

        return { changeLocale, availableLocales };
    };

    const i18n = useInstance(() => {
        return createI18n();
    });

    return <Context.Provider value={{ ...i18n, localeCode: language, isInitialized }}>{children}</Context.Provider>;
};

export default I18nProvider;
