import React, { ReactNode, useEffect, useState } from 'react';

import { Auth, CognitoUser } from '@aws-amplify/auth';

import { fetch, getBaseDomain, getInsideApiUrl, isLocalhost } from '@freelancelabs/shared';

import { useInstance } from '../../hooks';
import AuthenticationContext from './authenticationContext';
import { ChallengeName, ForgotPasswordSubmitInput, LoginCheckInput, LoginInput } from './interface';

interface Props {
    children?: ReactNode;
    awsCognitoRegion: string;
    awsUserPoolsId: string;
    awsUserPoolsWebClientId: string;
}

const isSecureCookie = () => !isLocalhost();

const AuthenticationProvider = ({ children, awsUserPoolsWebClientId, awsUserPoolsId, awsCognitoRegion }: Props) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [hasSession, setHasSession] = useState(false);

    useEffect(() => {
        const run = async () => {
            try {
                await Auth.currentSession();
                setHasSession(true);
            } catch (e) {
                setHasSession(false);
            } finally {
                setIsInitialized(true);
            }
        };

        run();
    }, []);

    const createAuthentication = () => {
        Auth.configure({
            aws_cognito_region: awsCognitoRegion,
            aws_user_pools_id: awsUserPoolsId,
            aws_user_pools_web_client_id: awsUserPoolsWebClientId,
            oauth: {},
            cookieStorage: {
                domain: getBaseDomain(),
                secure: isSecureCookie(),
            },
        });

        let currentUser: CognitoUser | null = null;

        const getAuthorizationToken = async (): Promise<string | null> => {
            try {
                const userSession = await Auth.currentSession();
                return userSession.getIdToken().getJwtToken();
            } catch (e) {
                return null;
            }
        };

        const completeNewPassword = async (password: string): Promise<void> => {
            if (currentUser === null) {
                return;
            }
            await Auth.completeNewPassword(currentUser, password);

            setHasSession(true);
        };

        const login = async ({ email, password }: LoginInput): Promise<ChallengeName | undefined> => {
            const user = await Auth.signIn({ username: email, password }, undefined);
            currentUser = user;
            if (user.challengeName) {
                return user.challengeName;
            }

            setHasSession(true);
        };

        const passwordLessLoginCheck = async ({ code, email }: LoginCheckInput): Promise<void> => {
            const user = await Auth.signIn(email, undefined);
            const userSession = await Auth.sendCustomChallengeAnswer(user, code);

            if (userSession?.signInUserSession?.accessToken) {
                setHasSession(true);
                return;
            }

            throw new Error('Invalid or expired code', { cause: { code: 401 } });
        };

        const passwordLessLogin = async (email: string): Promise<void> => {
            await fetch({
                method: 'post',
                url: `${getInsideApiUrl('auth/passwordless')}`,
                data: {
                    email,
                    clientId: import.meta.env.PUBLIC_AWS_USER_POOLS_WEB_CLIENT_ID,
                    validationUrl: `${window.location.origin}/login/check`,
                },
            });
        };

        const logout = async () => {
            await Auth.signOut();
            setHasSession(false);
            // @TODO remove cookie session, redirect or CB ?
        };

        const forgotPassword = (username: string) =>
            Auth.forgotPassword(username, {
                clientId: import.meta.env.PUBLIC_AWS_USER_POOLS_WEB_CLIENT_ID,
                validationUrl: `${window.location.origin}/update-password`,
                forgotPasswordUrl: `${window.location.origin}/reset-password`,
            });

        const forgotPasswordSubmit = ({ email, code, newPassword }: ForgotPasswordSubmitInput) =>
            Auth.forgotPasswordSubmit(email, code, newPassword);

        return {
            login,
            logout,
            completeNewPassword,
            forgotPassword,
            forgotPasswordSubmit,
            passwordLessLogin,
            passwordLessLoginCheck,
            getAuthorizationToken,
        };
    };

    const auth = useInstance(() => {
        return createAuthentication();
    });

    return (
        <AuthenticationContext.Provider value={{ ...auth, hasSession, isInitialized }}>
            {children}
        </AuthenticationContext.Provider>
    );
};

export default AuthenticationProvider;
