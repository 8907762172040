import { ReactNode } from 'react';

import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';

import { useAuthentication } from '@freelancelabs/components';

interface Props {
    children: ReactNode;
}

const ApiProvider = ({ children }: Props) => {
    const { getAuthorizationToken } = useAuthentication();
    const cache = new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    Deliveries: relayStylePagination(),
                    Invoices: relayStylePagination(),
                },
            },
        },
    });

    const httpLink = createHttpLink({
        uri: import.meta.env.PUBLIC_CLIENT_API_URL,
    });

    const authLink = setContext(async (_, { headers }) => {
        const token = await getAuthorizationToken();
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
            },
        };
    });

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache,
    });

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApiProvider;
