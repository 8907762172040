import React from 'react';

import { BrowserOptions, ErrorBoundary, init } from '@sentry/react';

import { MonitoringErrorBoundaryProps } from './interfaces';

const MonitoringErrorBoundary = ({ sentryConfig, errorBoundaryConfig, children }: MonitoringErrorBoundaryProps) => {
    const dsn = import.meta.env.PUBLIC_SENTRY_DSN;
    if (!dsn) {
        return children;
    }
    const defaultConfig: BrowserOptions = {
        dsn,
        environment: import.meta.env.PUBLIC_APP_STAGE,
    };
    init({ ...defaultConfig, ...sentryConfig });
    return <ErrorBoundary {...errorBoundaryConfig}>{children}</ErrorBoundary>;
};

export default MonitoringErrorBoundary;
