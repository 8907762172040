import React, { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { CircleLoader } from '@freelancelabs/components';

import Layout from '../app/content/Layout';
import { AppRoutes } from '../constants';

const DeliveriesPage = React.lazy(() => import('./Deliveries'));
const ReportsPage = React.lazy(() => import('./Reports'));
const HomePage = React.lazy(() => import('./Home'));
const TechnicalAssistancePage = React.lazy(() => import('./TechnicalAssistance'));
const SolicitationsPage = React.lazy(() => import('./Solicitations'));
const InvoicesPage = React.lazy(() => import('./Invoices'));
const FlatRatesPage = React.lazy(() => import('./FlatRates'));

const PageLoading = () => {
    return (
        <div className="flex flex-1 items-center justify-center h-full">
            <CircleLoader />
        </div>
    );
};

const Pages = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout />}>
                    <Route
                        index
                        path={AppRoutes.HOME}
                        element={
                            <Suspense fallback={<PageLoading />}>
                                <HomePage />
                            </Suspense>
                        }
                    />
                    <Route
                        path={AppRoutes.REPORTS}
                        element={
                            <Suspense fallback={<PageLoading />}>
                                <ReportsPage />
                            </Suspense>
                        }
                    />
                    <Route
                        path={AppRoutes.DELIVERIES}
                        element={
                            <Suspense fallback={<PageLoading />}>
                                <DeliveriesPage />
                            </Suspense>
                        }
                    />
                    <Route
                        path={AppRoutes.INVOICES}
                        element={
                            <Suspense fallback={<PageLoading />}>
                                <InvoicesPage />
                            </Suspense>
                        }
                    />
                    <Route
                        path={AppRoutes.SOLICITATIONS}
                        element={
                            <Suspense fallback={<PageLoading />}>
                                <SolicitationsPage />
                            </Suspense>
                        }
                    >
                        <Route
                            path={AppRoutes.TECHNICAL_ASSISTANCE}
                            element={
                                <Suspense fallback={<PageLoading />}>
                                    <TechnicalAssistancePage />
                                </Suspense>
                            }
                        />
                        <Route
                            path={AppRoutes.FLAT_RATE}
                            element={
                                <Suspense fallback={<PageLoading />}>
                                    <FlatRatesPage />
                                </Suspense>
                            }
                        />
                        <Route index element={<Navigate to={AppRoutes.TECHNICAL_ASSISTANCE} />} />
                    </Route>
                    <Route path="*" element={<Navigate to={AppRoutes.HOME} />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default Pages;
