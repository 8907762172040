import { eventBus } from '@freelancelabs/shared';

import { EventName, SUPPORT_EVENT_PREFIX } from '../constants';

const supportEvents = ({ track, update }) => {
    eventBus.on(EventName.DASHBOARD_PAGE_VIEWED, () => {
        track({ eventName: `${SUPPORT_EVENT_PREFIX}${EventName.DASHBOARD_PAGE_VIEWED}` });
        update();
    });

    eventBus.on(EventName.DELIVERIES_PAGE_VIEWED, () => {
        track({ eventName: `${SUPPORT_EVENT_PREFIX}${EventName.DELIVERIES_PAGE_VIEWED}` });
        update();
    });

    eventBus.on(EventName.INVOICES_PAGE_VIEWED, () => {
        track({ eventName: `${SUPPORT_EVENT_PREFIX}${EventName.INVOICES_PAGE_VIEWED}` });
    });

    eventBus.on(EventName.TECHNICAL_ASSISTANCE_PAGE_VIEWED, () => {
        track({ eventName: `${SUPPORT_EVENT_PREFIX}${EventName.TECHNICAL_ASSISTANCE_PAGE_VIEWED}` });
    });

    eventBus.on(EventName.FLAT_RATE_PAGE_VIEWED, () => {
        track({ eventName: `${SUPPORT_EVENT_PREFIX}${EventName.FLAT_RATE_PAGE_VIEWED}` });
    });

    eventBus.on(EventName.DISPLAY_COLUMNS_CTA_CLICKED, () => {
        track({ eventName: `${SUPPORT_EVENT_PREFIX}${EventName.DISPLAY_COLUMNS_CTA_CLICKED}` });
    });

    eventBus.on(EventName.DISPLAYED_COLUMNS_CHANGED, ({ id, isAdded }) => {
        track({
            eventName: `${SUPPORT_EVENT_PREFIX}${EventName.DISPLAYED_COLUMNS_CHANGED}`,
            data: { column: id, action: isAdded ? 'Column added' : 'Column hidden' },
        });
    });

    eventBus.on(EventName.EXPORT_REQUESTED, ({ allColumns, format }) => {
        track({
            eventName: `${SUPPORT_EVENT_PREFIX}${EventName.EXPORT_REQUESTED}`,
            data: { all_columns: allColumns ? 'Yes' : 'No', format },
        });
    });

    eventBus.on(EventName.DELIVERIES_RECURRING_EXPORT_MODAL_VIEWED, () => {
        track({ eventName: `${SUPPORT_EVENT_PREFIX}${EventName.DELIVERIES_RECURRING_EXPORT_MODAL_VIEWED}` });
    });

    eventBus.on(EventName.DELIVERIES_RECURRING_EXPORT_ACTIVATED, ({ format, recurrence }) => {
        track({
            eventName: `${SUPPORT_EVENT_PREFIX}${EventName.DELIVERIES_RECURRING_EXPORT_ACTIVATED}`,
            data: { format, recurrence },
        });
        update({ deliveries_recurring_export: true });
    });
};

export default supportEvents;
